import React from 'react'
import { FaArrowDown } from 'react-icons/fa'

const FindOutMore = () => {
  return (
    <div className="flex flex-row justify-evenly w-full items-center mt-6 hidden xl:inline-flex">
      <FaArrowDown className="text-secondary animate-bounce text-4xl mt-2 font-medium" />
      <span className='font-mont font-medium tracking-widest text-secondary text-xl md:text-2xl xl:text-4xl lowercase'>Find Out More</span>
      <FaArrowDown className="text-secondary animate-bounce text-4xl mt-2 font-medium" />
    </div>
  )
}

export default FindOutMore
