import React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const clientLogosQuery = graphql`
  {
    allFile(filter: { extension: { regex: "/(png)/" }, relativeDirectory: { eq: "clients" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`

const ClientsSection = ({ clients }) => {
  const {
    allFile: { edges: clientLogosNodes },
  } = useStaticQuery(clientLogosQuery)
  const clientLogos = clientLogosNodes.map(node => {
    return { logoName: node.node.name, image: getImage(node.node) }
  })

  return (
    <div className='bg-gray-300 bg-opacity-20 mt-8 md:mt-14 xl:mt-4 p-1 lg:p-2'>
      <div className='container flex flex-col justify-evenly items-center'>
        <span className='text-secondary text-center font-mont font-medium text-sm md:text-xl xl:text-2xl capitalize'>trusted around the world</span>
        <div className='w-full my-4'>
          <div className='flex flex-row justify-around items-center gap-4'>
            <div>
              <GatsbyImage
                image={clientLogos.find(logo => logo.logoName === 'bury-afc-logo').image}
                alt='Bury AFC Logo'
                className='w-full filter grayscale'
              />
            </div>
            <div>
              <GatsbyImage
                image={clientLogos.find(logo => logo.logoName === 'doterra-logo').image}
                alt='DoTERRA Logo'
                className='w-full filter grayscale'
              />
            </div>
            <div>
              <GatsbyImage
                image={clientLogos.find(logo => logo.logoName === 'muscle-food-logo').image}
                alt='Muscle Food Logo'
                className='w-full filter grayscale opacity-60'
              />
            </div>
            <div>
              <GatsbyImage
                image={clientLogos.find(logo => logo.logoName === 'regatta-logo').image}
                alt='Regatta Logo'
                className='w-full filter grayscale'
              />
            </div>
            <div>
              <GatsbyImage
                image={clientLogos.find(logo => logo.logoName === 'tickr-logo').image}
                alt='Tickr Logo'
                className='w-full filter grayscale opacity-60'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ClientsSection.defaultProps = {
  clients: [
    {
      name: 'Bury AFC',
      logo: 'bury-afc-logo.png',
    },
    {
      name: 'DoTERRA',
      logo: 'doterra-logo.png',
    },
    {
      name: 'Muscle Food',
      logo: 'muscle-food-logo.png',
    },
    {
      name: 'Regatta',
      logo: 'regatta-logo.png',
    },
    {
      name: 'Tickr',
      logo: 'tickr-logo.png',
    },
  ],
}

export default ClientsSection
