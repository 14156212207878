import React from 'react'

/* components */
import ArticlesSection from '../components/sections/ArticlesSection'
import BenefitsSection from '../components/sections/BenefitsSection'
import ClientsSection from '../components/sections/ClientsSection'
import FindOutMore from '../components/sections/FindOutMore'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ServicesSection from '../components/sections/ServicesSection'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const Home = ({
  data: {
    contentfulPage: pageData,
    allContentfulArticle: { nodes: articlesData },
  },
}) => {
  return (
    <Layout page={pageData} socialIcons showProgress>
      <FindOutMore />
      <ClientsSection />
      <ServicesSection />
      {pageData.benefits && (
        <BenefitsSection
          title={pageData.benefits.title}
          benefits={pageData.benefits.benefits}
        />
      )}
      {/*<WeDoCtoBanner />*/}
      <ArticlesSection title='Latest' articlesData={articlesData} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPage(pageLink: { eq: "/" }) {
      ...pageFields
    }
    allContentfulArticle(limit: 3, sort: { fields: publishDate, order: DESC }) {
      nodes {
        ...articleFields
      }
    }
  }
`

export default Home
