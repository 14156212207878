import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'
import WdcButton from './WdcButton'

const ServiceCard = ({ service, imageRight }) => {
  const {
    pageLink,
    hero,
    relatedService: {
      name,
      overview: { overview },
    },
  } = service
  const image = getImage(hero)
  return (
    <div
      className={
        imageRight
          ? 'flex flex-col md:flex-row-reverse md:mb-8 bg-gray-50 rounded-2xl shadow-lg'
          : 'flex flex-col md:flex-row md:mb-8 bg-gray-50 rounded-2xl shadow-lg'
      }
    >
      <div
        className={`flex w-full md:w-2/5 safari-image-fix ${
          imageRight ? 'md:rounded-r-2xl' : 'md:rounded-l-2xl'
        }`}
      >
        <GatsbyImage
          image={image}
          alt={name}
          className={`flex-1 h-full max-h-32 w-full md:max-h-full mb-4 md:mb-0 ${
            imageRight ? 'md:rounded-r-2xl' : 'md:rounded-l-2xl'
          }`}
          as='section'
        />
      </div>
      <div className='flex flex-col p-4 md:p-8 text-center md:text-left w-full md:w-3/5 self-center'>
        <Fade
          duration={300}
          cascade
          damping={0.8}
          fraction={0.5}
          direction='up'
          triggerOnce
        >
          <h2 className='m-0'>{name}</h2>
          <p>
            {overview
              ? `${overview}`
              : 'No overview was provided for this ServiceCard'}
          </p>
          <div className='flex justify-center md:justify-start mt-4'>
            <WdcButton
              buttonText='tell me more'
              buttonLink={pageLink}
              showIcon={true}
            />
          </div>
        </Fade>
      </div>
    </div>
  )
}

ServiceCard.defaultProps = {
  name: 'Bespoke Software Development',
  link: '/services',
  imageRight: true,
}

export default ServiceCard
