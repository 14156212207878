import React from 'react'
import ServiceCard from '../elements/ServiceCard'
import { useServicesList } from '../../custom-query-hooks/useServicesList'

const ServicesSection = () => {
  const services = useServicesList()

  return (
    <section className='container mt-4 lg:mt-12 w-full overflow-hidden'>
      {services.map((service, index) => (
        <ServiceCard key={index} service={service} imageRight={index !== 1} />
      ))}
    </section>
  )
}

export default ServicesSection
